<template>
 <div>
  <Loader v-if="loading"></Loader>
  <div v-else>
    <CRow>
      <CCol lg="12">
        <AbstractTable :fields="fields" :actions="actions" :items="patientQuests" title="Cuestionarios del paciente" labelItemPerPage="Cuestionarios por página:" 
        :addBtn="{ enable: true, text: 'Asignar nuevo cuestionario', event: 'addElement' }" @addElement="openModalAddPatient" @showDeleteModal="handleModal" :noResults="'No se han encontrado cuestionarios del paciente'" />
      </CCol>
    </CRow>
    <CModal class="c-modal" title="Eliminar" color="principal" :show.sync="addModal" :closeOnBackdrop="false">
          <p class="text-muted">
            Seleccione un cuestionario para asignar al paciente
            <strong>"{{ patient.name }} {{ patient.surname }}</strong>"
          </p>
          <select class="form-select" aria-label="Default select example" v-model="selectedQuest">
            <option selected disabled style="font-weight: bold;">Seleccionar cuestionario:</option>
            <option v-for="(item, index) in getNewsQuests" :key="index">
              {{ item.title }}
            </option>
          </select>
          <br>
          <p class="text-muted">
            Fecha en la que el paciente puede empezar a hacer el cuestionario:
          </p>
          <CInput v-model="dateQuest" type="date" horizontal class="date-input"/>

          <div slot="footer">
            <CButton class="btn btn-secondary" @click="closeAssignModal()" style="margin-right: 1em">
              Cerrar
            </CButton>
            <CButton color="success" @click="addQuestToPatient()" :disabled="disabledModalButton">
              Aceptar
            </CButton>
          </div>
    </CModal>
    <DeleteModal :modalContent="modalContent" @delete="deleteQuestFromPatient()" :showModal="deleteModal" @closeModal="closeModal" />
  </div>
 </div>
</template>

<script>
import AbstractTable from "@/components/Tables/AbstractTable.vue";
import DeleteModal from "@/components/Modals/DeleteModal.vue";
import Loader from "@/components/Loader.vue";
import { getPatientQuestsData, getPatientData, assignQuestToPatient, deleteQuestFromPatient } from "@/services/patients";
import { getQuestsData } from "@/services/quests"
import { isInArray, generateField } from '@/utils/utils'
export default {
  name: "PatientQuestsTable",
  components: { AbstractTable, DeleteModal, Loader },
  data() {
    return {
      patientQuests: [],
      patient: {},
      deleteQuestItem: null,
      allQuests: [],
      fields: [
        generateField("title", "Título", {styleData: { textAlign: 'center'}}),
        generateField("description", "Descripción"),
        generateField("completed", "Completados", {styleData: { textAlign: 'center'}}),
        generateField("periodicity", "Periocidad", {styleData: { textAlign: 'center'}}),
        generateField("actions", 'Acciones', { sorter: false, filter: false })
      ],
      loading: true,
      addModal: false,
      deleteModal: false,
      selectedQuest: "Seleccionar cuestionario:",
      dateQuest: "",
      actions: [
        {
          key: 'delete',
          label: 'Eliminar',
          event: 'showDeleteModal',
          classButton: 'btn btn-delete btn-sm btn-pill'
        },],
        modalContent: {
            title: 'Eliminar cuestionario',
            textBody: '',
            buttons: [
                {
                  textButton: 'Volver',
                  color: 'primary',
                  event: 'closeModal'
                },
                {
                  textButton: 'Aceptar',
                  color: 'danger',
                  event: 'delete'
                },
            ]
      }
    }
  },
  created() {
    this.receiveData()
  },
  computed: {
    /**
     * Con este metodo se crea un array que guarda los elementos que no se repiten entre dos arrays
     */
    getNewsQuests() {
      return [...this.allQuests, ...this.patientQuests].filter(item => !isInArray(item, this.allQuests) || !isInArray(item, this.patientQuests));
    },
    disabledModalButton() {
      let disabledBtn = true
      this.getNewsQuests.forEach(element => {
        if (this.selectedQuest == element.title && (!this.dateQuest == "" || !this.dateQuest == null || !this.dateQuest == undefined)) {
          disabledBtn = false
        }
      });
      return disabledBtn
    }
  },
  methods: {
    async receiveData() {
      await this.getPatientData()
      await this.getPatientQuests();
      await this.getAllQuests();
      this.selectedQuest = "Seleccionar cuestionario:"
      this.dateQuest = ""
    },
    /**
     * Función para obtener los datos del paciente.
     */
    getPatientData() {
      getPatientData(this.$route.params.id)
      .catch((error) => { this.$store.state.errorAlert = { status: true, msg: error.toString() }; this.loading = false; })
      .then((result) => this.patient = result)
    },
    /**
     * Función para obtener los cuestionarios del paciente.
     */
    getPatientQuests() {
        getPatientQuestsData(this.$route.params.id)
        .catch((error) => { this.$store.state.errorAlert = { status: true, msg: error.toString() }; this.loading = false; })
        .then((result) => this.patientQuests = result);
    },
    /**
     * Función para obtener todos los cuestionarios.
     */
    getAllQuests(){
      getQuestsData()
      .catch((error) => { this.$store.state.errorAlert = { status: true, msg: error.toString() }; this.loading = false; })
      .then((result) => {this.allQuests = result; this.loading = false;})
    },
    openModalAddPatient() {
      this.addModal = true
    },
    /**
     * Función para añadir un cuestionario al paciente.
     */
    addQuestToPatient(){
      let selectedElement = null
      if (this.disabledModalButton) return
      this.getNewsQuests.forEach(element => {
        if (this.selectedQuest == element.title) {
          selectedElement = element
        }
      });
      const fechaObj = new Date(this.dateQuest);
      const timestamp = fechaObj.getTime();
      if (selectedElement != null) {
        assignQuestToPatient(this.patient.id, selectedElement.id, timestamp).then(() => {this.addModal = false; this.receiveData()})
      }
    },
    /**
     * Método para controlar lo que se muestra en el Modal de Eliminar cuestionario.
     */
    handleModal(item) {
      this.deleteQuestItem = item;
      this.modalContent.textBody = `¿Está seguro de que desea desasignar el cuestionario: <strong>"${this.deleteQuestItem.title}</strong>" del usuario: <strong>${this.patient.name} ${this.patient.surname}</strong>? Esta acción no se puede deshacer.`,
      this.deleteModal = true;
    },
     /**
     * Método para eliminar un cuestionario del paciente.
     */
    deleteQuestFromPatient(){
      deleteQuestFromPatient(this.patient.id, this.deleteQuestItem.id).then(() => {this.deleteModal = false; this.receiveData()})
    },
    /**
     * Función para cerrar el Modal de eliminar.
    */
    closeModal(){
        this.deleteModal = false;
    },
    /**
     * Función para cerrar el Modal de asignar cuestionario.
    */
    closeAssignModal() {
      this.addModal = false
      this.selectedQuest = "Seleccionar cuestionario:"
      this.dateQuest = ""
    }
  }
};
</script>
<style scoped>
</style>